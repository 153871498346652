<!--预览-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title">{{title}}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <div class="w-e-text-container">
            <div v-html="contentDetail" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bottom-div">
      <!--上一篇，下一篇-->
      <el-row>
        <el-col :span="24" class="rowSpaceBetween" style="margin-top: 20px">
          <div>
            <span class="prev_next_span">上一篇</span>
            <span v-if="previous && previous.contentTitle" class="otherNewClass cursorPointer" @click="toPreOrNext(previous.contentId)">{{previous.contentTitle}}</span>
            <span v-else class="noneNewClass">没有了</span>
          </div>
          <div>
            <span class="prev_next_span">下一篇</span>
            <span v-if="nextNew && nextNew.contentTitle" class="otherNewClass cursorPointer" @click="toPreOrNext(nextNew.contentId)">{{nextNew.contentTitle}}</span>
            <span v-else class="noneNewClass">没有了</span>
          </div>
        </el-col>
      </el-row>
      <!--增加取消下方白条-->
      <div style="height: 44px"></div>
    </div>
  </div>
</template>

<script>
import {getDetailByd} from '@/utils/commonApi.js'
import topPathList from '@/components/topPathList'
import axios from 'axios'

export default {
  name: 'committeePreview',
  components: { topPathList },
  data() {
    return {
      pathObj: [],
      title: '',
      time: '',
      contentDetail: '',
      previous: {},
      nextNew: {},
      contentId: "",
      typeId: this.$route.query.typeId,
      contentType: sessionStorage.getItem('committeeContentType'),
    }
  },
  mounted () {
    let pathObj = JSON.parse(this.$route.query.pathObj);
    pathObj.push({
      name: '详情',
      path: '/committee/committeePreview'
    });
    this.pathObj = [...pathObj];
    let value = JSON.parse(this.$route.query.value);
    this.contentId = value.contentId;
    this.getDetail()
    this.$forceUpdate()
  },
  methods: {
    //根据文章id查询文章详情
    getDetail() {

      let params = {
        id: this.contentId,
        contentType: this.contentType,
        typeId: this.typeId,
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/contentType/querySpecialDetails",
        params: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          const formData = res.data.data;
          this.contentDetail = formData.data[0].contentDetail;
          this.title = formData.data[0].contentTitle;
          this.time = formData.data[0].createTime;
          this.previous = formData.lastData[0];
          this.nextNew = formData.nextData[0];
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },

    toPreOrNext(contentId) {
      this.contentId = contentId
      this.getDetail()
      document.documentElement.scrollTop = 0;
    },

  },
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.main-div {
  background-color: #f8f9fa !important;
}
.breadTopDiv {
  text-align: center;
  .breadTop {
    width: 1200px;
    margin: auto;
  }
}
.content-div {
  min-height: 385px;
  margin: auto;
  padding: 32px 40px 12px 40px;
  width: 1120px;
  background: #FFFFFF;
  .titleDiv {
    text-align: center;
  }
}
.news_title {
  font-size: 32px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
  margin-top: 52px;
}

.bottom-div {
  margin: auto;
  width: 1120px;
}
.prev_next_span {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #3E3A39;
  line-height: 24px;
  margin-right: 10px;
}
.otherNewClass {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #186CF5;
  line-height: 24px;
}
.noneNewClass {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #B2B0B0;
  line-height: 24px;
}
::v-deep .el-row {
  margin: 20px 0;
}

::v-deep .temp_font {
  text-align: left;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #3E3A39;
  line-height: 30px;
  text-indent: 2rem;
}

@media screen and (max-width: 1366px) {
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .content-div {
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1120px;
    background: #FFFFFF;
    .titleDiv {
      text-align: center;
    }
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    margin-top: 52px;
  }

  .bottom-div {
    margin: auto;
    width: 1120px;
  }
  .prev_next_span {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 24px;
    margin-right: 10px;
  }
  .otherNewClass {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 24px;
  }
  .noneNewClass {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #B2B0B0;
    line-height: 24px;
  }
  ::v-deep .el-row {
    margin: 20px 0;
  }

  ::v-deep .temp_font {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 30px;
    text-indent: 2rem;
  }
}
</style>
